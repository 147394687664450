import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'

import SEO from '../../components/seo'
import NewsSectionBlock from '../../components/NewsSectionBlock'
import Typography from '../../components/Common/Typography'
import List from '../../components/Common/List'
import ArrowButton from '../../components/Common/ArrowButton'
import ModalIframe from '../../components/Common/ModalIframe'
import Link from '../../components/Common/Link'

import YoutubePlayIcon from '../../svg/youtube-play-icon'

import './JuneDigest2024.styles.scss'

const JuneDigest2024 = () => {
  return (
    <>
      <SEO
        title="Digest of the latest updates on SIGNAX products"
        description="SIGNAX June 2024 Digest of updates and product materials, including SIGNAX DOCS CDE, INSPECTION - remote construction control service, DASHBOARD project management software and TOOLS solution for BIM management automation."
      />
      <NewsSectionBlock date="July 10, 2024">
        <Typography variant="h1" color="blue">
          June Digest SIGNAX
        </Typography>
        <Typography variant="h2" size={24}>
          Product Updates
        </Typography>
        <Typography variant="h3" size={18}>
          SIGNAX TOOLS
        </Typography>
        <Typography variant="body1">
          We have{' '}
          <b>
            completed the second development phase of the SIGNAX TOOLS module
          </b>
          , successfully achieving all functionalities and objectives:
        </Typography>
        <List>
          <li>Checking the BIM model for completeness of properties;</li>
          <li>
            Generating search sets based on properties values, rules described
            in Excel, or a classifier in .txt file format;
          </li>
          <li>Exporting a Bill of Quantities from the model;</li>
          <li>Generating a construction budget based on the model;</li>
          <li>Linking with the BIM 4D schedule;</li>
          <li>
            Managing the construction BIM model:
            <ul>
              <li>- Marking work completion in the model;</li>
              <li>- Attaching directives to model elements;</li>
              <li>- Attaching documentation to model elements.</li>
            </ul>
          </li>
          <li>Generating BOQ based on work completion from the BIM model.</li>
        </List>
        <Typography variant="body1">
          More BIM management features for SIGNAX TOOLS are being developed.
          Stay tuned!
        </Typography>
        <Typography variant="body1">
          Check out{' '}
          <b>latest workshop - SIGNAX TOOLS Turbocharge your BIM Projects</b>,
          the unique solution for BIM management automation made by engineers.
          Learn how SIGNAX TOOLS, leveraging Revit, Navisworks, and AutoCAD,
          make processes more efficient and convenient, saving time and budget
          of your project.
        </Typography>
        <Link
          href="https://www.youtube.com/watch?v=h4qdBqLoeFU"
          rel="nofollow noreferrer"
          target="_blank"
          style={{ fontSize: 18 }}
        >
          WORKSHOP | SIGNAX TOOLS Turbocharge your BIM Projects
        </Link>
        <SignaxDocsBlock />
        <LatestProductVideosBlock />
      </NewsSectionBlock>
    </>
  )
}

const SignaxDocsBlock = () => {
  const swiperRef = React.useRef<SwiperRef>(null)
  const [disabledNavigationPrev, setDisabledNavigationPrev] =
    React.useState(true)
  const [disabledNavigationNext, setDisabledNavigationNext] =
    React.useState(false)

  const images = useStaticQuery(imagesQuery)

  const docsData = [
    {
      text: 'Added a "Point" markup in PDF documentation issues',
      imageId: 'image1',
    },
    {
      text: 'Enhanced file download functionality: You can now download multiple folders and files. In shared links, you can download files/folders individually or in bulk',
      imageId: 'image2',
    },
    {
      text: 'Added bulk editing of Reviews',
      imageId: 'image3',
    },
    {
      text: 'Added bulk editing of Issues',
      imageId: 'image4',
    },
    {
      text: 'Added the ability to create an Approval Package directly from "Documents"',
      imageId: 'image5',
    },
    {
      text: 'Added DWG comparison',
      imageId: 'image6',
    },
    {
      text: 'Added search for projects and generally improved buttons and fonts in the design',
      imageId: 'image7',
    },
    {
      text: 'Added the ability to add attachments in issues from PC and phone, as well as attach links to external sources',
      imageId: 'image8',
    },
    {
      text: 'Added upload date to version designation',
      imageId: 'image9',
    },
    {
      text: 'Revised Issues statuses',
      imageId: 'image10',
    },
    {
      text: 'Added "Issues" block in Reviews. Reviews display attached Issues from files in Review',
      imageId: 'image11',
    },
    {
      text: 'Added the ability to select a sheet/view in model comparison. You can choose to compare 3D views or 2D sheets',
      imageId: 'image12',
    },
  ]

  return (
    <>
      <Typography variant="h3" size={18}>
        SIGNAX DOCS
      </Typography>
      <div className="flex items-center">
        <ArrowButton
          onClick={() => swiperRef.current?.swiper.slidePrev()}
          disabled={disabledNavigationPrev}
          arrowType="left"
          className="june-digest_btn"
        />
        <Swiper
          ref={swiperRef}
          freeMode={true}
          grabCursor={true}
          navigation={true}
          onSlideChange={swiper => {
            setDisabledNavigationPrev(swiper.isBeginning)
            setDisabledNavigationNext(swiper.isEnd)
          }}
          pagination={{
            clickable: true,
            bulletClass: 'june-digest_pagination',
            bulletActiveClass: 'active',
          }}
          modules={[Pagination]}
        >
          {docsData.map((item, idx) => (
            <SwiperSlide key={idx}>
              <div className="flex flex-col items-center mb-6">
                <Typography variant="body1">
                  {`${idx + 1} of ${docsData.length}. ${item.text}`}
                </Typography>
                <GatsbyImage
                  image={images[item.imageId].childImageSharp.gatsbyImageData}
                  alt={`SIGNAX DOCS ${idx + 1}`}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <ArrowButton
          arrowType="right"
          onClick={() => swiperRef.current?.swiper.slideNext()}
          disabled={disabledNavigationNext}
          className="june-digest_btn"
        />
      </div>
    </>
  )
}

const LatestProductVideosBlock = () => {
  const [youtubeData, setYoutubeData] = React.useState<{
    src: string
    title: string
    description: string
    imageId: string
  }>()
  const swiperRef = React.useRef<SwiperRef>(null)
  const [activeSlideIdx, setActiveSlideIdx] = React.useState(0)

  const [disabledNavigationPrev, setDisabledNavigationPrev] =
    React.useState(true)
  const [disabledNavigationNext, setDisabledNavigationNext] =
    React.useState(false)

  const images = useStaticQuery(imagesQuery)

  const youtubeVideo = [
    {
      src: 'https://www.youtube.com/embed/jjFCyMftGQg?si=CqNFbMVfY85gfnG0',
      title: 'SIGNAX DOCS CDE. How it works',
      description:
        'Discover SIGNAX DOCS, an innovative CDE in construction management. Streamline your document management with custom review processes, advanced features, and viewer that supports over 50 file formats on any device.',
      imageId: 'shortsPreview1',
    },
    {
      src: 'https://youtube.com/embed/FZtdHHd4B-4?si=6Xjwz7KTPQ95DtE3',
      title: 'SIGNAX DOCS: Documents viewer and collaboration',
      description:
        'Explore the power of SIGNAX DOCS CDE by opening your project documentation in the browser from any device. Effortlessly access office documents, media materials, CAD files, BIM models, and federated models with just a click.',
      imageId: 'shortsPreview2',
    },
    {
      src: 'https://youtube.com/embed/hwgjGLTO3l0?si=eny-6L1qchK5NkkV',
      title: 'SIGNAX DASHBOARD. How it Works',
      description:
        'Discover SIGNAX DASHBOARD, a cloud service for project management that delivers updates, metrics, and media straight to your mobile device or desktop. Streamline your workflow with features like smart cards, data visualization, budget reporting, and a mobile app for iOS and Android.',
      imageId: 'shortsPreview3',
    },
    {
      src: 'https://youtube.com/embed/phdjJCLlxXc?si=Esz_ckhkVQKc1BNO',
      title: 'SIGNAX TOOLS. How it works',
      description:
        'Discover SIGNAX TOOLS, a powerful solution for BIM management automation. Designed for designers, contractors, consultants, and owners, it streamlines tasks like backups, model checks, volume calculations and data export/import.',
      imageId: 'shortsPreview4',
    },
    {
      src: 'https://youtube.com/embed/lJU4EDx29w4?si=9YyQEcqvvS7uFGoE',
      title: 'SIGNAX INSPECTION. Aerial panoramas',
      description:
        'Introducing the SIGNAX INSPECTION Module for advanced remote monitoring and 360-degree aerial photography. Capture comprehensive site overviews, monitor progress and quality, and document key stages to enhance control, safety, and efficiency in your construction projects.',
      imageId: 'shortsPreview5',
    },
    {
      src: 'https://youtube.com/embed/dbeZ608dWFw?si=2g1t0_yQN9ADK1VX',
      title: 'Construction Site Management with SIGNAX',
      description:
        'Transform your construction site management with SIGNAX! Use SIGNAX DOCS point cloud feature for accurate measurements, regulatory compliance, efficient demolition planning, and comprehensive site inspections.',
      imageId: 'shortsPreview6',
    },
  ]

  return (
    <>
      <Typography variant="h2" size={24}>
        Latest product videos
      </Typography>
      <div className="flex items-center">
        <ArrowButton
          onClick={() => swiperRef.current?.swiper.slidePrev()}
          disabled={disabledNavigationPrev}
          arrowType="left"
          className="june-digest_btn"
        />
        <Swiper
          ref={swiperRef}
          freeMode={true}
          grabCursor={true}
          navigation={true}
          onSlideChange={swiper => {
            setDisabledNavigationPrev(swiper.isBeginning)
            setDisabledNavigationNext(swiper.isEnd)
            setActiveSlideIdx(swiper.activeIndex)
          }}
          centeredSlides
          wrapperClass="mb-6"
          pagination={{
            clickable: true,
            bulletClass: 'june-digest_pagination',
            bulletActiveClass: 'active',
          }}
          slidesPerView="auto"
          modules={[Pagination]}
        >
          {youtubeVideo.map((item, idx) => {
            const isActiveSlide = activeSlideIdx === idx
            return (
              <SwiperSlide key={idx} className="june-digest_slide">
                <div
                  style={{
                    opacity: !isActiveSlide ? 0.6 : undefined,
                  }}
                  className="relative june-digest_poster"
                  onClick={() => setYoutubeData(item)}
                >
                  <GatsbyImage
                    image={images[item.imageId].childImageSharp.gatsbyImageData}
                    className="w-100 h-100"
                    alt={item.title}
                  />
                  <YoutubePlayIcon
                    width={76}
                    height={76}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      zIndex: 1,
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </div>
                <div style={{ opacity: isActiveSlide ? 1 : 0 }}>
                  <Typography
                    variant="body1"
                    color="blue"
                    mt={24}
                    className="has-text-weight-medium mb-2"
                  >
                    {item.title}
                  </Typography>
                  <Typography variant="body1" className="m-0">
                    {item.description}
                  </Typography>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <ArrowButton
          arrowType="right"
          onClick={() => swiperRef.current?.swiper.slideNext()}
          disabled={disabledNavigationNext}
          className="june-digest_btn"
        />
      </div>
      {youtubeData && (
        <ModalIframe
          title={youtubeData.title}
          src={youtubeData.src}
          srcTitle={youtubeData.title}
          onClose={() => setYoutubeData(undefined)}
        />
      )}
    </>
  )
}

const imagesQuery = graphql`
  query {
    image1: file(relativePath: { eq: "news/june-digest-2024/image-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 540, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "news/june-digest-2024/image-2.png" }) {
      childImageSharp {
        gatsbyImageData(height: 540, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "news/june-digest-2024/image-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 540, placeholder: BLURRED)
      }
    }
    image4: file(relativePath: { eq: "news/june-digest-2024/image-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 540, placeholder: BLURRED)
      }
    }
    image5: file(relativePath: { eq: "news/june-digest-2024/image-5.png" }) {
      childImageSharp {
        gatsbyImageData(height: 540, placeholder: BLURRED)
      }
    }
    image6: file(relativePath: { eq: "news/june-digest-2024/image-6.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 540, placeholder: BLURRED)
      }
    }
    image7: file(relativePath: { eq: "news/june-digest-2024/image-7.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 540, placeholder: BLURRED)
      }
    }
    image8: file(relativePath: { eq: "news/june-digest-2024/image-8.png" }) {
      childImageSharp {
        gatsbyImageData(height: 540, placeholder: BLURRED)
      }
    }
    image9: file(relativePath: { eq: "news/june-digest-2024/image-9.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 540, placeholder: BLURRED)
      }
    }
    image10: file(relativePath: { eq: "news/june-digest-2024/image-10.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 540, placeholder: BLURRED)
      }
    }
    image11: file(relativePath: { eq: "news/june-digest-2024/image-11.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 540, placeholder: BLURRED)
      }
    }
    image12: file(relativePath: { eq: "news/june-digest-2024/image-12.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 540, placeholder: BLURRED)
      }
    }
    shortsPreview1: file(
      relativePath: { eq: "news/june-digest-2024/shorts-preview-1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 297, placeholder: BLURRED)
      }
    }
    shortsPreview2: file(
      relativePath: { eq: "news/june-digest-2024/shorts-preview-2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 297, placeholder: BLURRED)
      }
    }
    shortsPreview3: file(
      relativePath: { eq: "news/june-digest-2024/shorts-preview-3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 297, placeholder: BLURRED)
      }
    }
    shortsPreview4: file(
      relativePath: { eq: "news/june-digest-2024/shorts-preview-4.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 297, placeholder: BLURRED)
      }
    }
    shortsPreview5: file(
      relativePath: { eq: "news/june-digest-2024/shorts-preview-5.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 297, placeholder: BLURRED)
      }
    }
    shortsPreview6: file(
      relativePath: { eq: "news/june-digest-2024/shorts-preview-6.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 297, placeholder: BLURRED)
      }
    }
  }
`

export default JuneDigest2024
